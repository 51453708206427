@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: Poppins;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  /* Customize the scrollbar width */
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  /* Customize the scrollbar track */
  background-color: rgb(58, 58, 209); /* Set the color of the scrollbar track */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  /* Customize the scrollbar thumb */
  background-color: transparent; /* Set the color of the scrollbar thumb */
  border-radius: 6px;
}

.your-specific-div::-webkit-scrollbar {
  width: 0px; /* Set the width of the scrollbar */
}

.your-specific-div::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the scrollbar thumb */
  border-radius: 6px; /* Set the border radius of the scrollbar thumb */
}

.your-specific-div::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scrollbar track */
}
